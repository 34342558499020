import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function Navbar() {
    return (
        <Nav>
            <NavbarDecoration />
            <div className="toggle"></div>
            <div className="links">
                <ul>
                    <StyledLi>
                        <a href="https://docs.metaversalwar.com/" target="_blank" rel="noopener noreferrer">
                            W H I T E P A P E R
                        </a>
                    </StyledLi>
                    <StyledLi>
                        <a href="https://docs.metaversalwar.com/roadmap/technologies-used" target="_blank" rel="noopener noreferrer">
                           T E C H N O L O G I E S
                        </a>
                    </StyledLi>
                    <StyledLi>
                        <a href="https://polygonscan.com/token/0x529515c23c44c0d4057e13427cb54a2f52dc5c61" target="_blank" rel="noopener noreferrer">
                            S C A N  $ P R I M A R I S
                        </a>
                    </StyledLi>    
                </ul>
            </div>
        </Nav>
    );
}

const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    left: 10%;
    rigth: 10%;
    width: 80%;
    .toggle {
        display: none;
    }
    .links {
        height: 0.1vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(27, 27, 27, 0.7);
        clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
        padding: 2rem 1rem;
        font-size: 0.9rem;
        ul {
            display: flex;
            list-style-type: none;
            gap: 4rem;
            li {
                a {
                    color: rgba(255, 255, 255, 0.6);
                    text-decoration: none;
                    transition: 0.7s ease-in-out;
                    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
                    border-radius: 20px;
                    &:hover {
                        color: rgba(255, 255, 255, 1);
                        box-shadow: 0 5px 10px rgba(141, 81, 207, 1);
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        left: 10%;
        rigth: 10%;
        width: 80%; /* Ancho ajustado para dispositivos pequeños */
        .links {
            font-size: 0.8rem;
            transition: all 0.5s ease-in-out;
            ul {
                gap: 1rem;
                li {
                    margin: 0;
                    a {
                        padding: 0.5rem;
                    }
                }
            }
        }
    }

    @media (max-width: 1060px) {
        left: 10%;
        rigth: 10%;
        width: 80%; /* Ancho ajustado para dispositivos pequeños */
        .links {
            font-size: 0.75rem;
            transition: all 0.5s ease-in-out;
            ul {
                gap: 1rem;
                li {
                    margin: 0;
                    a {
                        padding: 0.5rem;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        left: 0;
        rigth: 0;
        width: 100%;
        .links {
            font-size: 0.6rem;
            transition: all 0.5s ease-in-out;
            ul {
                gap: 1rem;
                li {
                    margin: 0;
                    a {
                        padding: 0.5rem;
                    }
                }
            }
        }
    }
    
    @media (max-width: 576px) {
        left: 0;
        width: 100%;
        .toggle {
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            z-index: 1000;
        }
        .links {
            position: absolute;
            top: 10%;
            left: 0;
            width: 100%;
            padding: 0.5rem 0;
            height: auto;
            background-color: rgba(27, 27, 27, 0.9);
            clip-path: none;
            font-size: 0.42rem;
            transition: all 0.5s ease-in-out;
            ul {
                gap: 1rem;
                li {
                    margin: 0;
                    a {
                        padding: 0.5rem;
                    }
                }
            }
        }
    }

    @media (max-width: 340px) {
        left: 0;
        width: 100%;
        .toggle {
            position: absolute;
            display: block;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            z-index: 1000;
        }
        .links {
            position: absolute;
            top: 10%;
            left: 0;
            width: 100%;
            padding: 1rem 0;
            height: auto;
            background-color: rgba(27, 27, 27, 0.9);
            clip-path: none;
            font-size: 0.4rem;
            transition: all 0.5s ease-in-out;
            ul {
                gap: 0.2rem;
                li {
                    margin: 0;
                    a {
                        padding: 0.5rem;
                    }
                }
            }
        }
    }
`;

const StyledLi = styled.li`
    position: relative;
    &:not(:last-child)::after {
        content: '';
        position: absolute;
        height: 70%;
        width: 1px;
        background: linear-gradient(transparent 0%, #808080 30%, #808080 70%, transparent 100%),
        linear-gradient(transparent 0%, transparent 40%, #808080 60%, transparent 100%);
        top: 10%;
        right: -2rem;
    }

    @media (max-width: 1060px){
        &:not(:last-child)::after {
            right: -0.5rem;
        }
    }

    @media (max-width: 768px) {
        &:not(:last-child)::after {
            right: -0.7rem;
        }
    }

    @media (max-width: 576px) {
        &:not(:last-child)::after {
            right: 0;
            background: rgba(27, 27, 27, 0);
        }
    }
`;

const NavbarDecoration = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 40%;
    left: 30%;
    right: 30%;
    height: 0.5vh;
    border-radius: 50px;
    background-color: #808080;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 1);

    @media (max-width: 576px) {
        width: 80%;
        left: 10%;
        right: 10%;
    }
`;



